import { BookService } from "@/common/api.service";
import { Vue } from "vue-property-decorator";
import {
  FETCH_BOOKS,
  FETCH_MORE_BOOKS,
  FETCH_BOOKS_FILTER_PARAMS,
  GET_BOOK,
  UPDATE_BOOK_SHORTLISTS,
  UPDATE_BOOK_BASE_PAGE,
} from "./actions.type";
import {
  SET_BOOKS_START,
  SET_BOOKS,
  ADD_BOOKS,
  SET_BOOKS_FILTER_PARAMS,
  SET_BOOK_START,
  SET_BOOK,
  SET_BOOK_SHORTLISTS,
  SET_BOOK_BASE_PAGE,
} from "./mutations.type";

const state = {
  isBooksLoading: false,
  books: [],
  booksCount: 0,
  booksFilterParams: {
    publishers: [],
    imprints: [],
    subjects: [],
    price: {
      min: 0,
      max: 0,
    },
  },
  isBookLoading: false,
  book: {},
  bookBasePage: { name: "home" },
};

const getters = {
  isBooksLoading: (state) => state.isBooksLoading,
  books: (state) => state.books,
  booksCount: (state) => state.booksCount,
  booksFilterParams: (state) => state.booksFilterParams,
  isBookLoading: (state) => state.isBookLoading,
  book: (state) => state.book,
  bookBasePage: (state) => state.bookBasePage,
};

const actions = {
  async [FETCH_BOOKS]({ commit }, params) {
    commit(SET_BOOKS_START);
    const { data } = await BookService.filter(params);
    commit(SET_BOOKS, data.data);
  },
  async [FETCH_MORE_BOOKS]({ commit }, params) {
    const { data } = await BookService.filter(params);
    commit(ADD_BOOKS, data.data);
  },
  async [FETCH_BOOKS_FILTER_PARAMS]({ commit }, params) {
    const { data } = await BookService.fetchFilterParams(params);
    commit(SET_BOOKS_FILTER_PARAMS, data.data);
  },
  async [GET_BOOK]({ commit }, slug) {
    commit(SET_BOOK_START);
    const { data } = await BookService.get(slug);
    commit(SET_BOOK, data.data);
  },
  [UPDATE_BOOK_SHORTLISTS]({ commit }, params) {
    commit(SET_BOOK_SHORTLISTS, params);
  },
  [UPDATE_BOOK_BASE_PAGE]({ commit }, params) {
    commit(SET_BOOK_BASE_PAGE, params);
  },
};

const mutations = {
  [SET_BOOKS_START](state) {
    state.isBooksLoading = true;
  },
  [SET_BOOKS](state, { books, bookCount }) {
    state.books = books;
    state.booksCount = bookCount;
    state.isBooksLoading = false;
  },
  [ADD_BOOKS](state, { books }) {
    for (var i in books) {
      state.books.push(books[i]);
    }
  },
  [SET_BOOKS_FILTER_PARAMS](state, params) {
    state.booksFilterParams = params;
  },
  [SET_BOOK_START](state) {
    state.isBookLoading = true;
  },
  [SET_BOOK](state, { book }) {
    state.book = book;
    state.isBookLoading = false;
  },
  [SET_BOOK_SHORTLISTS](state, { bookId, shortlistIds }) {
    state.books.forEach((book, index) => {
      if (book.id === bookId) {
        Vue.set(state.books, index, {
          ...book,
          shortlistIds: [...shortlistIds],
        });
      }
    });
    if (state.book && state.book.id === bookId) {
      state.book.shortlistIds = [...shortlistIds];
    }
  },
  [SET_BOOK_BASE_PAGE](state, params) {
    state.bookBasePage = params;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
