export const LOGIN = "login";
export const LOGOUT = "logout";
export const GET_USER = "getUser";
export const UPDATE_PROFILE = "updateProfile";
export const CHANGE_PASSWORD = "changePassword";
export const ACCEPT_TERMS = "acceptTerms";

export const CREATE_JOIN_US = "createJoinUs";
export const REFRESH_JOIN_US = "refreshJoinUs";
export const CREATE_CONTACT_US = "createContactUs";
export const REFRESH_CONTACT_US = "refreshContactUs";

export const FETCH_SEARCH_INDEX = "fetchSearchIndex";
export const FETCH_RECOMMENDATION = "fetchRecommendation";

export const FETCH_BOOKS = "fetchBooks";
export const FETCH_MORE_BOOKS = "fetchMoreBooks";
export const FETCH_BOOKS_FILTER_PARAMS = "fetchBooksFilterParams";
export const GET_BOOK = "getBook";
export const UPDATE_BOOK_SHORTLISTS = "updateBookShortlists";
export const UPDATE_BOOK_BASE_PAGE = "updateBookBasePage";

export const FETCH_CATALOGS = "fetchCatalogs";
export const FETCH_MORE_CATALOGS = "fetchMoreCatalogs";
export const FETCH_CATALOGS_FILTER_PARAMS = "fetchCatalogsFilterParams";
export const GET_CATALOG = "getCatalog";
export const UPDATE_CATALOG_SHORTLISTS = "updateCatalogShortlists";
export const FETCH_CATALOG_BOOKS = "fetchCatalogBooks";
export const FETCH_MORE_CATALOG_BOOKS = "fetchMoreCatalogBooks";
export const FETCH_CATALOG_BOOKS_FILTER_PARAMS =
  "fetchCatalogBooksFilterParams";
export const UPDATE_CATALOG_BOOK_SHORTLISTS = "updateCatalogBookShortlists";

export const FETCH_PUBLISHERS = "fetchPublishers";
export const FETCH_MORE_PUBLISHERS = "fetchMorePublishers";
export const REFRESH_PUBLISHER_PAGE = "refreshPublisherPage";
export const GET_PUBLISHER = "getPublisher";
export const FETCH_PUBLISHER_CATALOGS = "fetchPublisherCatalogs";
export const FETCH_MORE_PUBLISHER_CATALOGS = "fetchMorePublisherCatalogs";
export const UPDATE_PUBLISHER_CATALOG_SHORTLISTS =
  "updatePublisherCatalogShortlists";
export const FETCH_PUBLISHER_BOOKS = "fetchPublisherBooks";
export const FETCH_MORE_PUBLISHER_BOOKS = "fetchMorePublisherBooks";
export const FETCH_PUBLISHER_BOOKS_FILTER_PARAMS =
  "fetchPublisherBooksFilterParams";
export const UPDATE_PUBLISHER_BOOK_SHORTLISTS = "updatePublisherBookShortlists";

export const CREATE_SHORTLIST = "createShortlist";
export const FETCH_SHORTLISTS = "fetchShortlists";
export const GET_SHORTLIST = "getShortlist";
export const LOAD_SELECTED_SHORTLIST = "loadSelectedShortlist";
export const TOGGLE_SHORTLIST_ID = "toggleShortlistId";
export const STORE_SELECTED_SHORTLIST = "storeSelectedShortlist";
export const RENAME_SHORTLIST = "renameShortlist";
export const CLEAR_SHORTLIST = "clearShortlist";
export const REMOVE_SHORTLIST = "removeShortlist";
export const FETCH_SHORTLIST_CATALOGS = "fetchShortlistCatalogs";
export const FETCH_SHORTLIST_PUBLISHER_BOOKS = "fetchShortlistPublisherBooks";
