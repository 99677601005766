import Vue from "vue";
import { PublisherService } from "@/common/api.service";
import {
  FETCH_PUBLISHERS,
  FETCH_MORE_PUBLISHERS,
  REFRESH_PUBLISHER_PAGE,
  GET_PUBLISHER,
  FETCH_PUBLISHER_CATALOGS,
  FETCH_MORE_PUBLISHER_CATALOGS,
  UPDATE_PUBLISHER_CATALOG_SHORTLISTS,
  FETCH_PUBLISHER_BOOKS,
  FETCH_MORE_PUBLISHER_BOOKS,
  FETCH_PUBLISHER_BOOKS_FILTER_PARAMS,
  UPDATE_PUBLISHER_BOOK_SHORTLISTS,
} from "./actions.type";
import {
  SET_PUBLISHERS_START,
  SET_PUBLISHERS,
  ADD_PUBLISHERS,
  SET_PUBLISHER_PAGE,
  SET_PUBLISHER,
  SET_PUBLISHER_CATALOGS_START,
  SET_PUBLISHER_CATALOGS,
  ADD_PUBLISHER_CATALOGS,
  SET_PUBLISHER_CATALOG_SHORTLISTS,
  SET_PUBLISHER_BOOKS_START,
  SET_PUBLISHER_BOOKS,
  ADD_PUBLISHER_BOOKS,
  SET_PUBLISHER_BOOKS_FILTER_PARAMS,
  SET_PUBLISHER_BOOK_SHORTLISTS,
} from "./mutations.type";

const initialPublisherPage = {
  breadcrumbsList: [
    {
      href: "/publishers",
      text: "Publishers",
    },
    {
      href: "#",
      text: "",
    },
  ],
};

const state = {
  isPublishersLoading: false,
  publishers: [],
  publishersCount: 0,
  publisherPage: { ...initialPublisherPage },
  publisher: {},
  isPublisherCatalogsLoading: false,
  publisherCatalogs: [],
  publisherCatalogsCount: 0,
  isPublisherBooksLoading: false,
  publisherBooks: [],
  publisherBooksCount: 0,
  publisherBooksFilterParams: {
    imprints: [],
    subjects: [],
    price: {
      min: 0,
      max: 0,
    },
  },
};

const getters = {
  isPublishersLoading: (state) => state.isPublishersLoading,
  publishers: (state) => state.publishers,
  publishersCount: (state) => state.publishersCount,
  publisherPage: (state) => state.publisherPage,
  publisher: (state) => state.publisher,
  isPublisherCatalogsLoading: (state) => state.isPublisherCatalogsLoading,
  publisherCatalogs: (state) => state.publisherCatalogs,
  publisherCatalogsCount: (state) => state.publisherCatalogsCount,
  isPublisherBooksLoading: (state) => state.isPublisherBooksLoading,
  publisherBooks: (state) => state.publisherBooks,
  publisherBooksCount: (state) => state.publisherBooksCount,
  publisherBooksFilterParams: (state) => state.publisherBooksFilterParams,
};

const actions = {
  async [FETCH_PUBLISHERS]({ commit }, params) {
    commit(SET_PUBLISHERS_START);
    const { data } = await PublisherService.filter(params);
    commit(SET_PUBLISHERS, data.data);
  },
  async [FETCH_MORE_PUBLISHERS]({ commit }, params) {
    const { data } = await PublisherService.filter(params);
    commit(ADD_PUBLISHERS, data.data);
  },
  [REFRESH_PUBLISHER_PAGE]({ commit }) {
    commit(SET_PUBLISHER_PAGE, { ...initialPublisherPage });
  },
  async [GET_PUBLISHER]({ commit }, slug) {
    const { data } = await PublisherService.get(slug);
    commit(SET_PUBLISHER, { ...data.data });
  },
  async [FETCH_PUBLISHER_CATALOGS]({ commit }, { slug, params }) {
    commit(SET_PUBLISHER_CATALOGS_START);
    const { data } = await PublisherService.filterCatalogs(slug, params);
    commit(SET_PUBLISHER_CATALOGS, data.data);
  },
  async [FETCH_MORE_PUBLISHER_CATALOGS]({ commit }, { slug, params }) {
    const { data } = await PublisherService.filterCatalogs(slug, params);
    commit(ADD_PUBLISHER_CATALOGS, data.data);
  },
  [UPDATE_PUBLISHER_CATALOG_SHORTLISTS]({ commit }, params) {
    commit(SET_PUBLISHER_CATALOG_SHORTLISTS, params);
  },
  async [FETCH_PUBLISHER_BOOKS]({ commit }, { slug, params }) {
    commit(SET_PUBLISHER_BOOKS_START);
    const { data } = await PublisherService.filterBooks(slug, params);
    commit(SET_PUBLISHER_BOOKS, data.data);
  },
  async [FETCH_MORE_PUBLISHER_BOOKS]({ commit }, { slug, params }) {
    const { data } = await PublisherService.filterBooks(slug, params);
    commit(ADD_PUBLISHER_BOOKS, data.data);
  },
  async [FETCH_PUBLISHER_BOOKS_FILTER_PARAMS]({ commit }, { slug, params }) {
    const { data } = await PublisherService.fetchBooksFilterParams(
      slug,
      params
    );
    commit(SET_PUBLISHER_BOOKS_FILTER_PARAMS, data.data);
  },
  [UPDATE_PUBLISHER_BOOK_SHORTLISTS]({ commit }, params) {
    commit(SET_PUBLISHER_BOOK_SHORTLISTS, params);
  },
};

const mutations = {
  [SET_PUBLISHERS_START](state) {
    state.isPublishersLoading = true;
  },
  [SET_PUBLISHERS](state, { publishers, publisherCount }) {
    state.publishers = publishers;
    state.publishersCount = publisherCount;
    state.isPublishersLoading = false;
  },
  [ADD_PUBLISHERS](state, { publishers }) {
    for (var i in publishers) {
      state.publishers.push(publishers[i]);
    }
  },
  [SET_PUBLISHER_PAGE](state, publisherPage) {
    state.publisherPage = publisherPage;
  },
  [SET_PUBLISHER](state, { publisher }) {
    state.publisher = publisher;
    Vue.set(state.publisherPage.breadcrumbsList[1], "text", publisher.name);
    Vue.set(
      state.publisherPage.breadcrumbsList[1],
      "href",
      `/publishers/${publisher.handle}`
    );
  },
  [SET_PUBLISHER_CATALOGS_START](state) {
    state.isPublisherCatalogsLoading = true;
  },
  [SET_PUBLISHER_CATALOGS](state, { catalogs, catalogCount }) {
    state.publisherCatalogs = catalogs;
    state.publisherCatalogsCount = catalogCount;
    state.isPublisherCatalogsLoading = false;
  },
  [ADD_PUBLISHER_CATALOGS](state, { catalogs }) {
    for (var i in catalogs) {
      state.publisherCatalogs.push(catalogs[i]);
    }
  },
  [SET_PUBLISHER_CATALOG_SHORTLISTS](state, { catalogId, shortlistIds }) {
    state.publisherCatalogs.forEach((catalog, index) => {
      if (catalog.id === catalogId) {
        Vue.set(state.publisherCatalogs, index, {
          ...catalog,
          shortlistIds: [...shortlistIds],
        });
      }
    });
  },
  [SET_PUBLISHER_BOOKS_START](state) {
    state.isPublisherBooksLoading = true;
  },
  [SET_PUBLISHER_BOOKS](state, { books, bookCount }) {
    state.publisherBooks = books;
    state.publisherBooksCount = bookCount;
    state.isPublisherBooksLoading = false;
  },
  [ADD_PUBLISHER_BOOKS](state, { books }) {
    for (var i in books) {
      state.publisherBooks.push(books[i]);
    }
  },
  [SET_PUBLISHER_BOOKS_FILTER_PARAMS](state, params) {
    state.publisherBooksFilterParams = params;
  },
  [SET_PUBLISHER_BOOK_SHORTLISTS](state, { bookId, shortlistIds }) {
    state.publisherBooks.forEach((book, index) => {
      if (book.id === bookId) {
        Vue.set(state.publisherBooks, index, {
          ...book,
          shortlistIds: [...shortlistIds],
        });
      }
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
