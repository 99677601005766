<template>
  <div class="terms-unaccepted">
    <div class="content">
      <h1 class="text-center text-5xl font-bold mb-5 mt-3">
        BOOKDATA.SG PUBLISHER SUBSCRIPTION AGREEMENT
      </h1>
      <p class="font-bold">
        Please read the folowwing carefully before using this Database or
        accessing, uploading or downloading any Book Data from this Database:
      </p>

      <ol class="list1">
        <li class="mb-3">
          The
          <span class="font-bold">SINGAPORE BOOK PUBLISHERS ASSOCIATION</span>
          ("<span class="font-bold">SBPA</span>") is a society registered under
          the Societies Act 1966 (UEN S68SS0043D), with registered address at 9
          Jurong Town Hall Road, #02-02 Jurong Town Hall, Singapore 609431. SBPA
          operates https://Bookdata.sg (the "<span class="font-bold"
            >Database</span
          >"), an Internet service providing, <i>inter alia</i>, an online
          repository of bibliographic information and other content relating to
          Publications accessible to third parties such as vendors of books and
          libraries;
        </li>
        <li class="mb-3">
          This is a legal agreement ("<span class="font-bold">Agreement</span>")
          between the book publisher (who will upload Book Data to the Database)
          (the "<span class="font-bold">Publisher</span>") and SBPA (each
          hereinafter referred to as a "<span class="font-bold">Party</span>" or
          collectively referred to as the "<span class="font-bold">Parties</span
          >". SBPA has agreed to allow the Publisher to contribute bibliographic
          information and other content relating to Publications to the Database
          on the terms of this Agreement;
        </li>
        <li class="mb-3">
          By clicking on the "Accept" button below, you agree to terms of this
          Agreement on behalf of the Publisher. You confirm that you are
          authorised to bind the Publisher to the terms of this Agreement; and
        </li>
        <li class="mb-3">
          If the Publisher has not agreed to the terms of this Licence, you must
          not click on the "Accept" button below, and you/the Publisher/the
          Publisher's employees must not use, access, upload or download any
          Book Data from this Database.
        </li>
      </ol>
      <p class="font-bold">
        Please save a copy of this Agreement for future reference.
      </p>

      <ol class="list2">
        <li>
          <span class="font-bold">DEFINITIONS AND INTERPRETATION</span>
          <ol class="list3 mt-3">
            <li class="mb-3">
              <div>
                <p>
                  In this Agreement, the folowwing words and phrases shall have
                  the meanings set out hereinafter:
                </p>
                <p>
                  "<strong>Additional Information</strong>" means any
                  information other than Mandatory Information that the Database
                  permits to be uploaded.
                </p>
                <p>
                  "<strong>Benchmark Interest Rate</strong>" refers to the
                  prevailing benchmarc interest rate set by the Association of
                  Banks in Singapore (regardless of the terminology used to
                  refer to the said benchmark interest rate). As of May 2022,
                  the benchmark interest rate is referred to as the Singapore
                  Interbank Offered Rate or SIBOR and it is anticipated that
                  after end 2024, the benchmark interest rate will be known as
                  the Singapore Overnight Rate Average or SORA.
                </p>
                <p>
                  "<strong>Book Data</strong>" refers to Mandatory Information
                  and Additional Information.
                </p>
                <p>
                  "<strong>Business Day</strong>" means a day when banks in
                  Singapore are open for banking business(but shall not include
                  Saturdays, Sundays and gazetted public holidays in Singapore).
                </p>
                <p>
                  "<strong>Commencement Date</strong>" means the date on which
                  this Agreement is accepted by the Publisher by clicking the
                  "Accept" button below.
                </p>
                <p>
                  "<strong>Data Format</strong>" refers to the formats set out
                  in [<span class="bg-yellow">location</span>]
                </p>
                <p>
                  "<strong>Fees</strong>" refers to the subscription fees sent
                  by SBPA to the Publisher via email
                </p>
                <p>
                  "<strong>Mandatory Information</strong>" means the information
                  specified by SBPA in the Mandatory Information downloadable
                  document that is available on the Publisher's account on the
                  Database, as may be amended by SBPA from time to time at its
                  sole discretion.
                </p>
                <p>
                  "<strong>Publications</strong>" refers to any publication:
                </p>
                <ol class="list4">
                  <li class="mb-3">
                    In which the Publisher holds the necesary legal right to
                    distribute the same in Singapore, i.e., the Publisher owns
                    the copyright in the publication, is a Singapore license of
                    the copyright in the publication and/or is the authorised
                    distribution agent in Singapore for the publication; and
                  </li>
                  <li class="mb-3">
                    Which is at the material time in print, out-of-print for not
                    more than three (3) years, or to be published in the next
                    three (3) months.
                  </li>
                  <li class="mb-3">
                    In addition to the foregoing, "Publications" shall also
                    include any publication to be determined at the sole
                    discretion of SBPA.
                  </li>
                </ol>
                <p>
                  "<strong>Third Party Contractor</strong>" refers to the third
                  party contractor that the Publisher may appoint in accordance
                  with Clause 3.2(i).
                </p>
              </div>
            </li>
            <li class="mb-3">
              Any reference to a statutory provision shall include such
              provision and any regulations made in pursuance thereof as from
              time to time modified or re‑enacted whether before or after the
              date of this Agreement so far as such modification or re‑enactment
              applies or is capable of applying to any transactions contemplated
              by or entered into in pursuance of this Agreement and (so far as
              liability thereunder may exist or can arise) shall include also
              any past statutory provisions or regulations (as from time to time
              modified or re‑enacted) which such provisions or regulations have
              directly or indirectly replaced.
            </li>
            <li class="mb-3">
              The <i>ejusdem generis</i> rule does not apply to this Agreement
              and accordingly general words introduced by the word “other” shall
              not be given a restrictive meaning by reason of the fact that they
              are preceded by words indicating a particular class of acts,
              matters or things. General words shall not be given a restrictive
              meaning by reason of the fact that they are followed by particular
              examples intended to be embraced by the general words.
            </li>
            <li class="mb-3">
              The headings in this Agreement are for convenience only and shall
              not affect the interpretation of the clauses thereunder.
            </li>
            <li class="mb-3">
              Unless the context otherwise requires, references to the singular
              number shall include references to the plural number and vice
              versa.
            </li>
          </ol>
        </li>
        <li>
          <strong>DURATION AND RENEWAL</strong>
          <ol class="list3 mt-3">
            <li>
              <p>
                This Agreement commences with effect from the Commencement Date
                and continues for a minimum period of one (1) year ("<strong
                  >Initial Term</strong
                >") unless terminated in accordance with this Agreement.
              </p>
            </li>
            <li>
              <p>
                At the expiration of the Initial Term, this Agreement will
                automatically renew for successive periods of one (1) year each
                ("<strong>Subsequent Term</strong>") unless terminated in
                accordance with this Agreement.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p class="font-bold">PUBLISHER'S OBLIGATIONS</p>
          <ol class="list3">
            <strong><u>Database</u></strong>
            <li>
              <p>
                The Publisher will be provided with an account to access the
                Database. The Publisher must ensure that the access details to
                the aforesaid account are only shared with the employees and/or
                Third Party Contractors that require access to the Database in
                order to perform the Publisher’s obligations in this Agreement,
                and take reasonable precautions too prevent unauthorised access
                to or use of the Database. The Publisher shall be responsible
                for all access to and use of the Database via its account,
                regardless whether authorised or unauthorised.
              </p>
            </li>
            <li>
              <p>
                The Publisher shall upload and update the Database as follows:
              </p>
              <ol class="list4">
                <li>
                  <p>
                    The Publisher shall upload onto the Database in a timely
                    manner the Mandatory Information of publications that are
                    Publications at the time of the Commencement Date, and
                    publications which qualify as Publications after the
                    Commencement Date.
                  </p>
                </li>
                <li>
                  The Publisher may at any time at its discretion upload to the
                  Database the Mandatory Information for publications that have
                  yet to qualify as Publications. To illustrate, the Publisher
                  may upload to the Database the Mandatory Information for a
                  publication that is to be published in the next four (4)
                  months, notwithstanding that such publication is yet to
                  qualify as a Publication.
                </li>
                <li>
                  <p>
                    The Publisher may upload onto the Database any Additional
                    Information.
                  </p>
                </li>
                <li>
                  <p>
                    All Mandatory Information and Additional Information
                    uploaded by the Publisher onto the Database shall be in the
                    Data Format.
                  </p>
                </li>
                <li>
                  <p>
                    On each Business Day, the Publisher shall update the
                    Mandatory Information and Additional Information uploaded by
                    it onto the Database to ensure that the aforesaid uploaded
                    Mandatory Information and Additional Information is accurate
                    and up-to-date.
                  </p>
                </li>
                <li>
                  <p>
                    The Publisher shall delete or remove, in a timely manner,
                    Mandatory Information and Additional Information uploaded by
                    it onto the Database in respect of any publication that has
                    been out-of-print for more than three (3) years.
                  </p>
                </li>
                <li>
                  <p>
                    SBPA may at any time at its sole discretion issue written
                    notice to the Publisher of any error or inaccuracy in
                    Mandatory Information or Additional Information. The
                    Publisher shall correct the same on the Database in a timely
                    manner unless some other deadline is stipulated by SBPA.
                  </p>
                </li>
                <li>
                  <p>
                    SBPA may at any time at its sole discretion edit, delete
                    and/or remove any out-of-date, inaccurate, or incorrect
                    Mandatory Information or Additional Information from the
                    Database.
                  </p>
                </li>
                <li>
                  <p>
                    With SBPA’s prior written consent, the Publisher may appoint
                    a Third Party Contractor to undertake its obligations in
                    this Clause 3.2. The appointment of any such Third Party
                    Contractor is subject to the following:
                  </p>
                  <ol class="list5">
                    <li>
                      <p>
                        On request from SBPA, the Publisher shall provide SBPA
                        with information on the Third Party Contractor. At
                        SBPA’s sole discretion, such information may include,
                        but is not limited to, the name of the Third Party
                        Contractor, the unique entity number, registered address
                        and contact details of the Third Party Contractor’s
                        representative(s);
                      </p>
                    </li>
                    <li>
                      <p>
                        The Publisher shall be responsible for all acts and
                        omissions of any such Third Party Contractor appointed
                        by it; and
                      </p>
                    </li>
                    <li>
                      <p>
                        Following the appointment of any such Third Party
                        Contractor, the Publisher may terminate such appointment
                        by giving written notice of the same to SBPA.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <strong><u>Estimated Publications</u></strong>
            <li>
              <p>
                The Publisher shall provide SBPA with a reasonable estimate of
                the total number of Publications that the Publisher will upload
                and update to the Database any Mandatory Information and/or
                Additional Information in accordance with the following
                timelines:
              </p>
              <ol class="list4">
                <li>
                  <p>
                    On the Commencement Date, with the aforesaid reasonable
                    estimate being in relation to the relevant calendar year;
                    and
                  </p>
                </li>
                <li>
                  <p>
                    No later than thirty (30) days before the start of each
                    calendar year throughout the duration of this Agreement,
                    with the aforesaid reasonable estimate being in relation to
                    the that upcoming calendar year.
                  </p>
                </li>
              </ol>
            </li>
            <strong><u>Payment of Fees and Taxes</u></strong>
            <li>
              <p>
                The Publisher shall provide SBPA with a reasonable estimate of
                the total number of Publications that the Publisher will upload
                and update to the Database any Mandatory Information and/or
                Additional Information in accordance with the following
                timelines:
              </p>
            </li>
            <li>
              <p>
                SBPA may vary the Fees at its sole discretion by notice in
                writing to the Publisher no later than fourteen (14) days before
                the end of any calendar year and any such variation shall be
                effective from the commencement of the following calendar year.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p class="font-bold">SBPA’S OBLIGATIONS</p>
          <ol class="list3">
            <li>
              <p>
                For the duration of this Agreement, SBPA shall provide
                administrative and minor technical support and training to the
                Publisher in relation to the Database in the manner set out at
                Platform
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p class="font-bold">INTELLECTUAL PROPERTY AND COPYRIGHT LICENCE</p>
          <ol class="list3">
            <li>
              <p>
                The Publisher grants to SBPA a worldwide perpetual, irrevocable,
                royalty-free licence (including the right to sub-licence) to use
                the copyright in the Mandatory Information and Additional
                Information for the purposes of reproducing, communicating,
                adapting, publishing and distributing the Mandatory Information
                and Additional Information via the Database.
              </p>
            </li>
            <li>
              <p>
                On behalf of the Publisher, the SBPA may grant sub-licences to
                third parties to use the copyright in the Mandatory Information
                and Additional Information to reproduce, communicate and
                distribute the Mandatory Information and Additional Information
                sourced by these third parties from the Database for the
                purposes of distributing, marketing, advertising, promoting,
                selling, loaning and/or exhibiting the relevant Publications,
                including any purposes incidental to and/or related to the
                aforesaid purposes.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p class="font-bold">CONFIDENTIAL INFORMATION</p>
          <ol class="list3">
            <li>
              <p>
                <strong>Confidential Information</strong> means any information
                which relates to the business, financial records, affairs,
                plans, operations, market opportunities, processes, trade
                secrets, know-how or any other information of a similar nature
                of either Party but excludes any information in clause 6.2.
              </p>
            </li>
            <li>
              <p>
                Information is not Confidential Information if it is or becomes
                generally available to the public by means other than a breach
                of this Agreement or if Parties agree in writing in advance that
                the relevant information is not Confidential Information.
              </p>
            </li>
            <li>
              <p>
                The Parties shall at all times keep confidential any
                Confidential Information and shall not use such Confidential
                Information except for the purposes of performing their
                obligations under or in connection with this Agreement.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p class="font-bold">WARRANTIES & INDEMNITY</p>
          <ol class="list3">
            <li>
              <p>
                The Publisher acknowledges that SBPA is entering into this
                Agreement on the basis of, and in reliance on, the following
                warranties:
              </p>
              <ol class="list4">
                <li>
                  <p>
                    The Publisher has the necessary legal rights to perform its
                    obligations in this Agreement, including any necessary
                    intellectual property rights and/or licence to upload and
                    update Mandatory Information and Additional Information to
                    the Database;
                  </p>
                </li>
                <li>
                  <p>
                    Mandatory Information and/or Additional Information uploaded
                    by the Publisher to the Database will comply with all
                    statutory requirements, including but not limited to the
                    Copyright Act 2021. In particular, the Publisher warrants
                    that the uploaded Mandatory Information and/or Additional
                    Information will comply with the applicable provisions
                    relating to moral rights in the Copyright Act 2021;
                  </p>
                </li>
                <li>
                  <p>
                    Mandatory Information and/or Additional Information uploaded
                    by the Publisher to the Database do not and will not
                    infringe any intellectual property or other legal (including
                    statutory) rights;
                  </p>
                </li>
                <li>
                  <p>
                    Mandatory Information and/or Additional Information uploaded
                    by the Publisher to the Database will be accurate in all
                    material respects and not be misleading or deceptive, or
                    likely to mislead or deceive.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                The Publisher shall procure that its employees, officers,
                representatives and/or agents shall not, do or omit to do
                anything during the duration of this Agreement that would be
                inconsistent with any term of this Agreement or cause any breach
                of warranty in clause 7.1.
              </p>
            </li>
            <li>
              <p>
                The Publisher shall fully indemnify SBPA against all damages,
                losses, costs and expenses (including without limitation legal
                and other professional fees and disbursements) incurred by SBPA
                arising out of any breach of the Publisher of clause 7.1 and/or
                7.2.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p class="font-bold">LIMITATION OF LIABILITY</p>
          <p>
            Except in circumstances where there is a breach of clauses 6 or 7:
          </p>
          <ol class="list4">
            <li>
              <p>
                Each Party’s liability under this Agreement shall be limited to
                the amount of fees paid by the Publisher in the calendar year in
                which the liability arose;
              </p>
            </li>
            <li>
              <p>
                No Party will be liable to the other (whether in contract, tort
                or otherwise) for any loss of profit or any consequential,
                special, incidental or direct loss or damage which may arise.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p class="font-bold">SUSPENSION & TERMINATION</p>
          <ol class="list3">
            <li>
              <p>Upon termination of this Agreement for whatever reason:</p>
              <ol class="list4">
                <li>
                  <p>
                    SBPA may at its sole discretion delete and/or remove any or
                    some or all of the Mandatory Information and/or the
                    Additional Information from the Database;
                  </p>
                </li>
                <li>
                  <p>
                    The Publisher’s access to the Database (including its
                    account on the Database) may be suspended and/or revoked
                    with immediate effect, at SBPA’s sole discretion;
                  </p>
                </li>
                <li>
                  <p>
                    Any sub-licences granted to third parties under clause 5.2
                    shall remain effective in respect of all Mandatory
                    Information and Additional Information uploaded by the
                    Publisher onto the Database unless SBPA at its sole
                    discretion deletes or removes the same from the Database, in
                    which case the Publisher may at its sole discretion revoke
                    the sub-licences granted in respect of the deleted Mandatory
                    Information and Additional Information. In the event that
                    the Publisher revokes the sub-licences, the Publisher grants
                    the relevant sub-licencees a period of ninety (90) days to
                    cease their use of the Publisher’s Mandatory Information and
                    Additional Information sourced from the Database.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                This Agreement shall automatically terminate with immediate
                effect if the Registrar of Societies publishes a notification in
                the Gazette that SBPA ceases to exist or is deemed to cease to
                exist.
              </p>
            </li>
            <strong><u>Termination or Suspension by SBPA</u></strong>
            <li>
              <p>
                SBPA may terminate this Agreement by written notice to the
                Publisher with immediate effect, with no refund of any Fees paid
                by the Publisher to SBPA, in the event that the Publisher:
              </p>
              <ol class="list4">
                <li>
                  <p>
                    Goes into liquidation, has a receiver or judicial manager
                    appointed to it or any part of its assets, enters into a
                    scheme of arrangement with creditors, commences winding up
                    proceedings or any other form of external administration
                    unless such any of the aforesaid proceedings are those
                    referred to in Section 440(6) the Insolvency, Restructuring
                    and Dissolution Act 2018 of Singapore (as may be amended
                    from time to time), in which case this Agreement shall not
                    be terminated; or
                  </p>
                </li>
                <li>
                  <p>
                    Fails to remedy a breach of any of its material obligations
                    under this Agreement which is capable of remedy, within
                    thirty (30) days of receipt of written notice from SBPA of
                    any such breach; or
                  </p>
                </li>
                <li>
                  <p>
                    Breaches any of its obligations under this Agreement which
                    is not capable of remedy.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                SBPA may at its sole discretion suspend the Publisher’s access
                to the Database (including its account on the Database) and/or
                provision of any administrative and minor technical support
                and/or training in relation to the Database, in the event that
                the Publisher has not paid any amount due to SBPA under this
                Agreement.
              </p>
            </li>
            <li>
              <p>
                SBPA may terminate this Agreement by written notice to the
                Publisher with effect from the end of the Initial Term or
                relevant Subsequent Term (as the case may be), provided that
                such written notice is given to the Publisher not less than
                sixty (60) days before the end of the relevant Initial Term or
                Subsequent Term.
              </p>
            </li>
            <strong><u>Termination by Publisher</u></strong>
            <li>
              <p>
                The Publisher may terminate this Agreement by written notice to
                SBPA with immediate effect, with no refund of any Fees paid by
                the Publisher to SBPA, in the event that SBPA:
              </p>
              <ol class="list4">
                <li>
                  <p>Proposes to dissolve itself voluntarily;</p>
                </li>
                <li>
                  <p>
                    Fails to remedy a breach of any of its material obligations
                    under this Agreement which is capable of remedy, within
                    thirty (30) days of receipt of written notice from SBPA of
                    any such breach; or
                  </p>
                </li>
                <li>
                  <p>
                    Breaches any of its obligations under this Agreement which
                    is not capable of remedy.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                The Publisher may terminate this Agreement by written notice to
                SBPA with effect from the end of the Initial Term or relevant
                Subsequent Term (as the case may be), provided that such written
                notice is given to SBPA not less than sixty (60) days before the
                end of the relevant Initial Term or Subsequent Term.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p class="font-bold">FORCE MAJEURE</p>
          <ol class="list3">
            <li>
              <p>
                Subject to compliance with clause 10.2, neither Party will be
                liable or responsible to the other Party, nor be deemed to have
                defaulted or breached this Agreement, for any failure or delay
                in fulfilling or performing any term of this Agreement when and
                to the extent such failure or delay is caused by an event beyond
                its reasonable control ("<span>Force Majeure Event</span>"),
                such as:
              </p>
              <ol class="list4">
                <li>
                  <p>Flood, earthquake, tornado, fire or explosion;</p>
                </li>
                <li>
                  <p>War, terrorism, invasion or riot;</p>
                </li>
                <li>
                  <p>
                    Embargoes or blockades in effect on or after the date of
                    this Agreement;
                  </p>
                </li>
                <li>
                  <p>National or regional emergency;</p>
                </li>
                <li>
                  <p>
                    Disruption to the Internet or telecommunications services.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>In the event of a Force Majeure Event:</p>
              <ol class="list4">
                <li>
                  <p>
                    The affected Party must promptly notify the other Party with
                    reasonable particulars of the Force Majeure Event and the
                    probable extent to which the affected Party will be unable
                    to perform or be delayed in performing any obligation(s)
                    under this Agreement;
                  </p>
                </li>
                <li>
                  <p>
                    The affected Party must use all reasonable diligence to
                    overcome or remove the Force Majeure Event as quickly as
                    possible;
                  </p>
                </li>
                <li>
                  <p>
                    The affected obligation(s) of this Agreement shall be
                    suspended for the duration of the Force Majeure Event.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                In the event that the Force Majeure Event continues for more
                than three (3) months, either Party may terminate this Agreement
                with immediate effect by written notice to the other Party.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p class="font-bold">MISCELLANEOUS</p>
          <ol class="list3">
            <li>
              <strong>Entire Agreement</strong>
              <p>
                This Agreement constitutes the entire agreement between the
                parties and supersedes and extinguishes all previous
                discussions, negotiations, correspondence, drafts, agreements,
                promises, assurances, warranties, representations and/or
                understandings between them, whether written or oral, relating
                to the subject matter of the Agreement.
              </p>
            </li>
            <li>
              <strong>Variation</strong>
              <p>
                No variation of this Agreement shall be effective unless it is
                in writing and signed by the Parties (or their authorised
                representatives).
              </p>
            </li>
            <li>
              <strong>Waiver</strong>
              <p>
                A waiver of any right or remedy under this Agreement is only
                effective if it is given in writing and signed by the Party
                waiving such right or remedy. Any such waiver shall apply only
                to the circumstances for which it is given and shall not be
                deemed a waiver of any subsequent breach or default. A failure
                or delay by a Party to exercise any right or remedy under this
                Agreement or by law shall not be deemed to constitute a waiver
                of that or any other right or remedy.
              </p>
            </li>
            <li>
              <strong>Notices</strong>
              <ol class="list4">
                <li>
                  <p>
                    Any notice given to a party under or in connection with this
                    Agreement shall be in writing and in the English language.
                    All notices shall be sent by email and a Party may also send
                    a duplicate notice by registered post in addition to email.
                  </p>
                </li>
                <li>
                  <p>
                    Notices sent by email shall be sent to SBPA at
                    <a href="mailto:bookdata@singaporebookpublishers.sg"
                      >bookdata@singaporebookpublishers.sg</a
                    >
                    and to the Publisher at the email associated with the
                    Publisher’s account on the Database. Notices sent by email
                    shall be deemed received at the time of transmission.
                  </p>
                </li>
                <li>
                  <p>
                    Notices sent by registered post shall be sent to SBPA at 9
                    Jurong Town Hall Road, #02-02 Jurong Town Hall, Singapore
                    609431 and to the Publisher at its address as stated at the
                    beginning of this Agreement. Notices sent by registered post
                    shall be deemed received at the time recorded by the
                    delivery service. A Party may change its details for service
                    of notices by registered post by giving email notice to the
                    other Party and any such change shall take effect on the
                    date five (5) Business Days after deemed receipt of the
                    notice of change.
                  </p>
                </li>
                <li>
                  <p>
                    This clause 11.4 does not apply to service of any
                    proceedings or documents in any legal action or other method
                    of dispute resolution.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <strong>Severance</strong>
              <p>
                If any term or part of a term of this Agreement is or becomes
                invalid, illegal or unenforceable, it shall be deemed modified
                to the minimum extent necessary to make it valid, legal and
                enforceable. If such modification is not possible, the relevant
                term or part of a term shall be deemed deleted. Any modification
                to or deletion of a term or part of a term under this clause
                shall not affect the validity and enforceability of the rest of
                this Agreement.
              </p>
            </li>
            <li>
              <strong>Survival</strong>
              <p>
                Other than obligations that have been fully performed, this
                Agreement remains in full force after termination, including but
                not limited to clause 6 and 7.
              </p>
            </li>
            <li>
              <strong>Exclusion of Third Party Rights</strong>
              <p>
                The Contracts (Rights of Third Parties) Act (Chapter 53B) of
                Singapore (in the form as may be amended from time to time)
                shall not under any circumstances apply to this Agreement and
                any person who is not a party to this Agreement (whether or not
                such person shall be named, referred to, or otherwise
                identified, or form part of a class of persons so named,
                referred to or identified, in this Agreement) shall have no
                right whatsoever under the Contracts (Rights of Third Parties)
                Act (Chapter 53B) of Singapore to enforce this Agreement or any
                of its terms.
              </p>
            </li>
            <li>
              <strong>Counterparts</strong>
              <p>
                This Agreement may be executed in any number of counterparts,
                each of which when executed shall constitute a duplicate
                original, but all the counterparts shall together constitute the
                one agreement.
              </p>
            </li>
            <li>
              <strong>Governing Law</strong>
              <p>
                This Agreement and any dispute or claim arising out of or in
                connection with it or its subject matter or formation shall be
                governed by and construed in accordance with the laws of
                Singapore.
              </p>
            </li>
            <li>
              <strong>Dispute Resolution</strong>
              <p>
                Any dispute arising out of or in connection with this Licence
                must first be submitted for mediation at the Singapore Mediation
                Centre ("<strong>SMC</strong>") in accordance with SMC’s
                Mediation Procedure in force for the time being. Either party
                may submit a request to mediate to SMC upon which the other
                party will be bound to participate in the mediation within
                thirty (30) days thereof. Every party to the mediation must be
                represented by senior executive personnel, of at least the
                seniority of a Head of Department or its equivalent, with
                authority to negotiate and settle the dispute. Unless otherwise
                agreed by the parties, the Mediator(s) will be appointed by SMC.
                The mediation will take place in Singapore in the English
                language and the parties agree to be bound by any settlement
                agreement reached.
              </p>
            </li>
          </ol>
        </li>
      </ol>

      <p class="font-bold mt-3">
        By clicking on the “Accept” button below, you agree to the terms of this
        Licence on behalf of the Publisher.
      </p>

      <button
        class="
          bg-orange-900
          border border-orange-900
          text-white-900
          rounded
          text-base
          font-medium
          px-4
          py-2.5
          hover:bg-white-900 hover:text-orange-900
          mt-3
        "
        @click.prevent="handleSubmit"
      >
        Accept
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleSubmit() {
      this.$emit("accept-terms");
    },
  },
};
</script>
