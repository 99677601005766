export const SET_AUTH = "setAuth";
export const SET_AUTH_ERROR = "setAuthError";
export const PURGE_AUTH = "purgeAuth";
export const SET_USER = "setUser";
export const SET_USER_NAME = "setUserName";
export const SET_USER_ERROR = "setUserError";
export const SET_SHORTLIST_COUNT = "setShortlistCount";
export const SET_CHANGE_PASSWORD_ERROR = "setChangePasswordError";
export const SET_PAGE_LOADING_START = "setPageLoadingStart";
export const SET_PAGE_LOADING_END = "setPageLoadingEnd";
export const SET_TERMS_UNACCEPTED = "setTermsUnaccepted";

export const RESET_JOIN_US = "resetJoinUs";
export const SET_JOIN_US_SUCCESS = "setJoinUsSuccess";
export const SET_JOIN_US_ERROR = "setJoinUsError";
export const RESET_CONTACT_US = "resetContactUs";
export const SET_CONTACT_US_SUCCESS = "setContactUsSuccess";
export const SET_CONTACT_US_ERROR = "setContactUsError";

export const SET_SEARCH_INDEX = "setSearchIndex";
export const SET_RECOMMENDATION = "setRecommendation";

export const SET_BOOKS_START = "setBooksStart";
export const SET_BOOKS = "setBooks";
export const ADD_BOOKS = "addBooks";
export const SET_BOOKS_FILTER_PARAMS = "setBooksFilterParams";
export const SET_BOOK_START = "setBookStart";
export const SET_BOOK = "setBook";
export const SET_BOOK_SHORTLISTS = "setBookShortlists";
export const SET_BOOK_BASE_PAGE = "setBookBasePage";

export const SET_CATALOGS_START = "setCatalogsStart";
export const SET_CATALOGS = "setCatalogs";
export const ADD_CATALOGS = "addCatalogs";
export const SET_CATALOGS_FILTER_PARAMS = "setCatalogsFilterParams";
export const SET_CATALOG = "setCatalog";
export const SET_CATALOG_SHORTLISTS = "setCatalogShortlists";
export const SET_CATALOG_BOOKS_START = "setCatalogBooksStart";
export const SET_CATALOG_BOOKS = "setCatalogBooks";
export const ADD_CATALOG_BOOKS = "addCatalogBooks";
export const SET_CATALOG_BOOKS_FILTER_PARAMS = "setCatalogBooksFilterParams";
export const SET_CATALOG_BOOK_SHORTLISTS = "setCatalogBookShortlists";

export const SET_PUBLISHERS_START = "setPublishersStart";
export const SET_PUBLISHERS = "setPublishers";
export const ADD_PUBLISHERS = "addPublishers";
export const SET_PUBLISHER_PAGE = "setPublisherPage";
export const SET_PUBLISHER = "setPublisher";
export const SET_PUBLISHER_CATALOGS_START = "setPublisherCatalogsStart";
export const SET_PUBLISHER_CATALOGS = "setPublisherCatalogs";
export const ADD_PUBLISHER_CATALOGS = "addPublisherCatalogs";
export const SET_PUBLISHER_CATALOG_SHORTLISTS = "setPublisherCatalogShortlists";
export const SET_PUBLISHER_BOOKS_START = "setPublisherBooksStart";
export const SET_PUBLISHER_BOOKS = "setPublisherBooks";
export const ADD_PUBLISHER_BOOKS = "addPublisherBooks";
export const SET_PUBLISHER_BOOKS_FILTER_PARAMS =
  "setPublisherBooksFilterParams";
export const SET_PUBLISHER_BOOK_SHORTLISTS = "setPublisherBookShortlists";

export const SET_SHORTLISTS = "setShortlists";
export const SET_ACTIVE_SHORTLIST = "setActiveShortlist";
export const ADD_SHORTLIST = "addShortlist";
export const SET_SELECTED_SHORTLIST = "setSelectedShortlist";
export const ADD_SHORTLIST_ID = "addShortlistId";
export const DELETE_SHORTLIST_ID = "removeShortlistId";
export const UPDATE_SHORTLIST = "updateShortlist";
export const RESET_SHORTLIST = "resetShortlist";
export const DELETE_SHORTLIST = "deleteShortlist";
export const SET_SHORTLIST_CATALOGS_START = "setShortlistCatalogsStart";
export const SET_SHORTLIST_CATALOGS = "setShortlistCatalogs";
export const SET_SHORTLIST_PUBLISHER_BOOKS_START =
  "setShortlistPublisherBooksStart";
export const SET_SHORTLIST_PUBLISHER_BOOKS = "setShortlistPublisherBooks";
