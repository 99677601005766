import Vue from "vue";
import Vuex from "vuex";

import app from "./app.module";
import search from "./search.module";
import page from "./page.module";
import publisher from "./publisher.module";
import catalog from "./catalog.module";
import book from "./book.module";
import shortlist from "./shortlist.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    search,
    page,
    publisher,
    catalog,
    book,
    shortlist,
  },
});
