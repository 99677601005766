<template>
  <div id="app" class="h-full">
    <div v-if="isPageLoading" class="flex h-full justify-center items-center">
      <img class="h-32" src="@/assets/preloader.gif" />
    </div>
    <terms-unaccepted v-else-if="isTermUnaccepted" />
    <layout-app v-else class="flex flex-col h-full">
      <router-view />
    </layout-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LayoutApp from "@/layouts/LayoutApp";
import TermsUnaccepted from "./components/app/TermsUnaccepted.vue";
import { GET_USER } from "@/store/actions.type";

export default {
  components: {
    LayoutApp,
    TermsUnaccepted,
  },
  computed: {
    ...mapGetters(["isPageLoading", "isAuthenticated", "isTermUnaccepted"]),
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$store.dispatch(GET_USER);
    }
  },
};
</script>
