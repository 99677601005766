import { AuthService } from "@/common/api.service";
import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import {
  LOGIN,
  LOGOUT,
  GET_USER,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  ACCEPT_TERMS,
} from "./actions.type";
import {
  SET_AUTH,
  SET_AUTH_ERROR,
  PURGE_AUTH,
  SET_USER,
  SET_USER_NAME,
  SET_USER_ERROR,
  SET_SHORTLIST_COUNT,
  SET_CHANGE_PASSWORD_ERROR,
  SET_PAGE_LOADING_START,
  SET_PAGE_LOADING_END,
  SET_TERMS_UNACCEPTED,
} from "./mutations.type";

const state = {
  user: {
    email: "",
    name: "Full Name",
    organizationName: "ORG. NAME",
    shortlistCount: 0,
  },
  userError: "",
  authError: "",
  isAuthenticated: !!JwtService.getToken(),
  isPageLoading: !!JwtService.getToken(),
  isTermUnaccepted: false,
  changePasswordError: "",
};

const getters = {
  user: (state) => state.user,
  userError: (state) => state.userError,
  authError: (state) => state.authError,
  isAuthenticated: (state) => state.isAuthenticated,
  isPageLoading: (state) => state.isPageLoading,
  isTermUnaccepted: (state) => state.isTermUnaccepted,
  changePasswordError: (state) => state.changePasswordError,
};

const actions = {
  async [LOGIN](context, params) {
    try {
      const { data } = await AuthService.login(params);
      await context.commit(SET_AUTH, data);
      context.dispatch(GET_USER);
    } catch (error) {
      const data = error.response.data;
      if (data.error === "invalid_grant") {
        context.commit(SET_AUTH_ERROR, "Invalid email or password.");
      } else {
        context.commit(SET_AUTH_ERROR, "There was a problem with the server.");
      }
    }
  },
  async [LOGOUT]({ commit }) {
    try {
      await AuthService.logout();
    } catch (error) {
      // ignore the error
    }
    commit(PURGE_AUTH);
  },
  async [GET_USER]({ commit }) {
    commit(SET_PAGE_LOADING_START);
    try {
      const { data } = await AuthService.getUser();
      commit(SET_USER, data.data);
    } catch (error) {
      if (error.response.status === 401) {
        commit(PURGE_AUTH);
      }

      if (
        error.response.status === 403 &&
        error.response.data.message === "terms_unaccepted"
      ) {
        commit(SET_TERMS_UNACCEPTED, true);
        commit(SET_USER, error.response.data.data);
      }
    }
    commit(SET_PAGE_LOADING_END);
  },
  async [UPDATE_PROFILE]({ commit }, params) {
    try {
      await AuthService.updateProfile(params);
      commit(SET_USER_NAME, params.name);
      commit(SET_USER_ERROR, "");
    } catch (error) {
      commit(SET_USER_ERROR, error.response.data.error);
    }
  },
  async [CHANGE_PASSWORD]({ commit }, params) {
    try {
      await AuthService.changePassword(params);
      commit(SET_CHANGE_PASSWORD_ERROR, "");
      commit(PURGE_AUTH);
    } catch (error) {
      commit(SET_CHANGE_PASSWORD_ERROR, error.response.data.error);
    }
  },
  async [ACCEPT_TERMS]({ commit, dispatch }) {
    try {
      await AuthService.acceptTerms();
      dispatch(GET_USER);
      commit(SET_TERMS_UNACCEPTED, false);
    } catch (error) {
      // ignore the error
    }
  },
};

const mutations = {
  [SET_AUTH](state, { access_token }) {
    state.isAuthenticated = true;
    JwtService.saveToken(access_token);
    ApiService.setHeader("Authorization", `Bearer ${JwtService.getToken()}`);
  },
  [SET_AUTH_ERROR](state, message) {
    state.authError = message;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    JwtService.destroyToken();
    ApiService.removeHeader("Authorization");
  },
  [SET_USER](state, user) {
    state.user = user;
  },
  [SET_USER_NAME](state, name) {
    state.user.name = name;
  },
  [SET_USER_ERROR](state, error) {
    state.userError = error;
  },
  [SET_SHORTLIST_COUNT](state, shortlistCount) {
    state.user.shortlistCount = shortlistCount;
  },
  [SET_CHANGE_PASSWORD_ERROR](state, error) {
    state.changePasswordError = error;
  },
  [SET_PAGE_LOADING_START](state) {
    state.isPageLoading = true;
  },
  [SET_PAGE_LOADING_END](state) {
    state.isPageLoading = false;
  },
  [SET_TERMS_UNACCEPTED](state, isTermUnaccepted) {
    state.isTermUnaccepted = isTermUnaccepted;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
