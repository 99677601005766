import { PageService } from "@/common/api.service";
import {
  CREATE_JOIN_US,
  REFRESH_JOIN_US,
  CREATE_CONTACT_US,
  REFRESH_CONTACT_US,
} from "./actions.type";
import {
  SET_JOIN_US_SUCCESS,
  SET_JOIN_US_ERROR,
  RESET_JOIN_US,
  SET_CONTACT_US_SUCCESS,
  SET_CONTACT_US_ERROR,
  RESET_CONTACT_US,
} from "./mutations.type";

const state = {
  joinUsSuccess: false,
  joinUsError: "",
  contactUsSuccess: false,
  contactUsError: "",
};

const getters = {
  joinUsSuccess: (state) => state.joinUsSuccess,
  joinUsError: (state) => state.joinUsError,
  contactUsSuccess: (state) => state.contactUsSuccess,
  contactUsError: (state) => state.contactUsError,
};

const actions = {
  async [CREATE_JOIN_US]({ commit }, params) {
    try {
      await PageService.joinUs(params);
      commit(SET_JOIN_US_SUCCESS);
    } catch (error) {
      const data = error.response.data;
      commit(SET_JOIN_US_ERROR, data.error);
    }
  },
  [REFRESH_JOIN_US]({ commit }) {
    commit(RESET_JOIN_US);
  },
  async [CREATE_CONTACT_US]({ commit }, params) {
    try {
      await PageService.contactUs(params);
      commit(SET_CONTACT_US_SUCCESS);
    } catch (error) {
      const data = error.response.data;
      commit(SET_CONTACT_US_ERROR, data.error);
    }
  },
  [REFRESH_CONTACT_US]({ commit }) {
    commit(RESET_CONTACT_US);
  },
};

const mutations = {
  [SET_JOIN_US_SUCCESS](state) {
    state.joinUsSuccess = true;
  },
  [SET_JOIN_US_ERROR](state, message) {
    state.joinUsError = message;
  },
  [RESET_JOIN_US](state) {
    state.joinUsSuccess = false;
    state.joinUsError = "";
  },
  [SET_CONTACT_US_SUCCESS](state) {
    state.contactUsSuccess = true;
  },
  [SET_CONTACT_US_ERROR](state, message) {
    state.contactUsError = message;
  },
  [RESET_CONTACT_US](state) {
    state.contactUsSuccess = false;
    state.contactUsError = "";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
