<template>
  <div class="bg-white-900 mb-14 mt-2">
    <div class="container mx-auto h-full">
      <div class="flex h-full">
        <div class="m-auto w-1/2 text-center">
          <!-- logo -->
          <router-link class="inline-block mb-8" to="/">
            <img
              alt="bookdata-logo"
              src="@/assets/logo-header.svg"
              width="400"
            />
          </router-link>

          <!-- input part -->
          <div class="flex items-center px-7 py-4 border">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.7556 20.5783L16.0682 14.8908C17.1699 13.53 17.8332 11.8009 17.8332 9.91758C17.8332 5.55262 14.2815 2.00098 9.91656 2.00098C5.55161 2.00098 2 5.55258 2 9.91754C2 14.2825 5.55165 17.8341 9.9166 17.8341C11.7999 17.8341 13.5291 17.1708 14.8899 16.0692L20.5773 21.7566C20.7398 21.9191 20.9531 22.0008 21.1665 22.0008C21.3798 22.0008 21.5932 21.9191 21.7557 21.7566C22.0815 21.4308 22.0815 20.9041 21.7556 20.5783ZM9.9166 16.1675C6.46996 16.1675 3.66666 13.3642 3.66666 9.91754C3.66666 6.47089 6.46996 3.6676 9.9166 3.6676C13.3632 3.6676 16.1665 6.47089 16.1665 9.91754C16.1665 13.3642 13.3632 16.1675 9.9166 16.1675Z"
                fill="#212022"
              />
            </svg>
            <input
              class="mx-4 flex-grow placeholder-grey-700 placeholder text-base"
              type="text"
              placeholder="Search for books by keyword / title / author / ISBN"
              v-model="searchValue"
              @keyup.enter="handleSearch"
            />
            <button
              type="button"
              class="text-grey-700 font-medium text-base"
              :class="{ invisible: !searchValue }"
              @click="searchValue = ''"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    if (this.$route.name === "book") {
      this.$modal.show("book-modal", { handle: this.$route.params.handle });
    }
  },
  data() {
    return {
      searchValue: "",
    };
  },
  methods: {
    handleSearch() {
      this.$router.push({ name: "search", query: { q: this.searchValue } });
    },
  },
};
</script>
