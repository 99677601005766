import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import JwtService from "./jwt.service";
import { API_URL, API_CLIENT_ID, API_CLIENT_SECRET } from "@/common/config";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    if (JwtService.getToken()) {
      Vue.axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${JwtService.getToken()}`;
    }
  },

  setHeader(key, value) {
    Vue.axios.defaults.headers.common[key] = value;
  },

  removeHeader(key) {
    delete Vue.axios.defaults.headers.common[key];
  },

  get(resource, slug = "", params = {}) {
    try {
      return Vue.axios.get(`${resource}/${slug}`, { params });
    } catch (error) {
      throw new Error(`ApiService ${error}`);
    }
  },

  getBlob(resource, slug = "") {
    try {
      return Vue.axios.get(`${resource}/${slug}`, { responseType: "blob" });
    } catch (error) {
      throw new Error(`ApiService ${error}`);
    }
  },

  post(resource, slug = "", params = {}) {
    try {
      return Vue.axios.post(`${resource}/${slug}`, params);
    } catch (error) {
      throw new Error(`ApiService ${error}`);
    }
  },

  put(resource, slug = "", params = {}) {
    try {
      return Vue.axios.put(`${resource}/${slug}`, params);
    } catch (error) {
      throw new Error(`ApiService ${error}`);
    }
  },

  delete(resource, slug) {
    try {
      return Vue.axios.delete(`${resource}/${slug}`);
    } catch (error) {
      throw new Error(`ApiService ${error}`);
    }
  },
};

export const AuthService = {
  login({ username, password }) {
    return ApiService.post("login", "", {
      grant_type: "password",
      client_id: API_CLIENT_ID,
      client_secret: API_CLIENT_SECRET,
      username,
      password,
      scope: "",
    });
  },
  logout() {
    return ApiService.post("logout");
  },
  forgotPassword(params) {
    return ApiService.post("forgot-password", "", params);
  },
  resetPassword(params) {
    return ApiService.post("reset-password", "", params);
  },
  getUser() {
    return ApiService.get("user");
  },
  updateProfile(params) {
    return ApiService.put("user", "", params);
  },
  changePassword(params) {
    return ApiService.put("user", "password", params);
  },
  acceptTerms() {
    return ApiService.post("user/accept-terms");
  },
};

export const PageService = {
  joinUs(params) {
    return ApiService.post("join", "", params);
  },
  contactUs(params) {
    return ApiService.post("contact-us", "", params);
  },
};

export const SearchService = {
  getIndex() {
    return ApiService.get("search", "index");
  },
  getRecommendation(params) {
    return ApiService.get("search", "recommendation", params);
  },
};

export const BookService = {
  filter(params) {
    return ApiService.post("books", "", params);
  },
  fetchFilterParams(params) {
    return ApiService.get("books", "filter-params", params);
  },
  get(slug) {
    return ApiService.get("books", slug);
  },
  getPdf(slug) {
    return ApiService.getBlob("books", `${slug}/pdf`);
  },
};

export const CatalogService = {
  filter(params) {
    return ApiService.post("catalogs", "", params);
  },
  fetchFilterParams(params) {
    return ApiService.get("catalogs", "filter-params", params);
  },
  get(slug) {
    return ApiService.get("catalogs", slug);
  },
  getPdf(slug) {
    return ApiService.getBlob("catalogs", `${slug}/pdf`);
  },
  filterBooks(slug, params) {
    return ApiService.post("catalogs", `${slug}/books`, params);
  },
  fetchBooksFilterParams(slug, params) {
    return ApiService.get("catalogs", `${slug}/books/filter-params`, params);
  },
};

export const PublisherService = {
  filter(params) {
    return ApiService.post("publishers", "", params);
  },
  get(slug = "") {
    return ApiService.get("publishers", slug);
  },
  getPdf(slug) {
    return ApiService.getBlob("publishers", `${slug}/pdf`);
  },
  filterCatalogs(slug, params) {
    return ApiService.post("publishers", `${slug}/catalogs`, params);
  },
  filterBooks(slug, params) {
    return ApiService.post("publishers", `${slug}/books`, params);
  },
  fetchBooksFilterParams(slug, params) {
    return ApiService.get("publishers", `${slug}/books/filter-params`, params);
  },
};

export const ShortlistService = {
  fetch() {
    return ApiService.get("shortlists");
  },
  get(slug) {
    return ApiService.get("shortlists", slug);
  },
  create(params) {
    return ApiService.post("shortlists", "", params);
  },
  storeBook(slug, params) {
    return ApiService.post("shortlists/books", slug, params);
  },
  storeCatalog(slug, params) {
    return ApiService.post("shortlists/catalogs", slug, params);
  },
  rename(slug, params) {
    return ApiService.put("shortlists", slug, params);
  },
  reset(slug) {
    return ApiService.post("shortlists", `${slug}/reset`);
  },
  delete(slug) {
    return ApiService.delete("shortlists", slug);
  },
  fetchCatalogs(slug) {
    return ApiService.post("shortlists", `${slug}/catalogs`);
  },
  fetchPublisherBooks(slug, publisherSlug) {
    return ApiService.post("shortlists", `${slug}/publishers/${publisherSlug}`);
  },
  getShortlistPdf(slug) {
    return ApiService.getBlob("shortlists", `${slug}/pdf`);
  },
  getShortlistCsv(slug) {
    return ApiService.getBlob("shortlists", `${slug}/csv`);
  },
  getShortlistOnix(slug) {
    return ApiService.getBlob("shortlists", `${slug}/onix`);
  },
  getPublisherPdf(slug, publisherSlug) {
    return ApiService.getBlob(
      "shortlists",
      `${slug}/publishers/${publisherSlug}/pdf`
    );
  },
  getPublisherCsv(slug, publisherSlug) {
    return ApiService.getBlob(
      "shortlists",
      `${slug}/publishers/${publisherSlug}/csv`
    );
  },
  getPublisherOnix(slug, publisherSlug) {
    return ApiService.getBlob(
      "shortlists",
      `${slug}/publishers/${publisherSlug}/onix`
    );
  },
};

export default ApiService;
