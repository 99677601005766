import { extend } from "vee-validate";
import { required, email, min, max } from "vee-validate/dist/rules";

extend("required", { ...required, message: "{_field_} is required." });
extend("email", { ...email, message: "Invalid email." });
extend("min", {
  ...min,
  message: "The {_field_} must be at least {length} characters.",
});
extend("max", {
  ...max,
  message: "The {_field_} may not be greater than {length} characters",
});
