import Vue from "vue";
import { ShortlistService } from "../common/api.service";
import {
  FETCH_SHORTLISTS,
  GET_SHORTLIST,
  CREATE_SHORTLIST,
  LOAD_SELECTED_SHORTLIST,
  TOGGLE_SHORTLIST_ID,
  STORE_SELECTED_SHORTLIST,
  UPDATE_BOOK_SHORTLISTS,
  UPDATE_CATALOG_BOOK_SHORTLISTS,
  UPDATE_PUBLISHER_BOOK_SHORTLISTS,
  UPDATE_CATALOG_SHORTLISTS,
  UPDATE_PUBLISHER_CATALOG_SHORTLISTS,
  RENAME_SHORTLIST,
  CLEAR_SHORTLIST,
  REMOVE_SHORTLIST,
  FETCH_SHORTLIST_CATALOGS,
  FETCH_SHORTLIST_PUBLISHER_BOOKS,
} from "./actions.type";
import {
  SET_SHORTLISTS,
  SET_ACTIVE_SHORTLIST,
  ADD_SHORTLIST,
  SET_SELECTED_SHORTLIST,
  ADD_SHORTLIST_ID,
  DELETE_SHORTLIST_ID,
  DELETE_SHORTLIST,
  RESET_SHORTLIST,
  UPDATE_SHORTLIST,
  SET_SHORTLIST_COUNT,
  SET_SHORTLIST_CATALOGS_START,
  SET_SHORTLIST_CATALOGS,
  SET_SHORTLIST_PUBLISHER_BOOKS_START,
  SET_SHORTLIST_PUBLISHER_BOOKS,
} from "./mutations.type";

const state = {
  shortlists: [],
  activeShortlist: {},
  selectedShortlist: {
    shortlistIds: [],
    bookId: null,
    catalogId: null,
  },
  isShortlistCatalogsLoading: false,
  shortlistCatalogs: {
    shortlist: {},
    catalogs: [],
  },
  isShortlistPublisherBooksLoading: false,
  shortlistPublisherBooks: {
    shortlist: {},
    publisher: {},
    books: [],
  },
};

const getters = {
  shortlists: (state) => state.shortlists,
  activeShortlist: (state) => state.activeShortlist,
  selectedShortlist: (state) => state.selectedShortlist,
  isShortlistCatalogsLoading: (state) => state.isShortlistCatalogsLoading,
  shortlistCatalogs: (state) => state.shortlistCatalogs,
  isShortlistPublisherBooksLoading: (state) =>
    state.isShortlistPublisherBooksLoading,
  shortlistPublisherBooks: (state) => state.shortlistPublisherBooks,
};

const actions = {
  async [FETCH_SHORTLISTS]({ commit }) {
    const { data } = await ShortlistService.fetch();
    commit(SET_SHORTLISTS, data.data);
  },
  async [GET_SHORTLIST]({ commit }, slug) {
    const { data } = await ShortlistService.get(slug);
    commit(SET_ACTIVE_SHORTLIST, data.data);
  },
  async [CREATE_SHORTLIST]({ commit, state }, params) {
    const { data } = await ShortlistService.create(params);
    commit(ADD_SHORTLIST, data.data);
    commit(SET_SHORTLIST_COUNT, state.shortlists.length);
  },
  [LOAD_SELECTED_SHORTLIST]({ commit }, params) {
    commit(SET_SELECTED_SHORTLIST, params);
  },
  [TOGGLE_SHORTLIST_ID]({ commit, state }, params) {
    const { shortlistId } = params;
    if (!state.selectedShortlist.shortlistIds.includes(shortlistId)) {
      commit(ADD_SHORTLIST_ID, params);
    } else {
      commit(DELETE_SHORTLIST_ID, params);
    }
  },
  async [STORE_SELECTED_SHORTLIST]({ state, dispatch }) {
    const { selectedShortlist } = state;
    if (selectedShortlist.bookId) {
      const { bookId, shortlistIds } = selectedShortlist;
      await ShortlistService.storeBook(bookId, {
        shortlistIds,
      });
      dispatch(UPDATE_BOOK_SHORTLISTS, { bookId, shortlistIds });
      dispatch(UPDATE_CATALOG_BOOK_SHORTLISTS, { bookId, shortlistIds });
      dispatch(UPDATE_PUBLISHER_BOOK_SHORTLISTS, { bookId, shortlistIds });
    } else if (selectedShortlist.catalogId) {
      const { catalogId, shortlistIds } = selectedShortlist;
      await ShortlistService.storeCatalog(catalogId, {
        shortlistIds,
      });
      dispatch(UPDATE_CATALOG_SHORTLISTS, { catalogId, shortlistIds });
      dispatch(UPDATE_PUBLISHER_CATALOG_SHORTLISTS, {
        catalogId,
        shortlistIds,
      });
    }
  },
  async [RENAME_SHORTLIST]({ commit }, { slug, params }) {
    const { data } = await ShortlistService.rename(slug, params);
    commit(UPDATE_SHORTLIST, data.data);
  },
  async [CLEAR_SHORTLIST]({ commit, state }) {
    await ShortlistService.reset(state.activeShortlist.id);
    commit(RESET_SHORTLIST);
  },
  async [REMOVE_SHORTLIST]({ commit, dispatch, state }, slug) {
    await ShortlistService.delete(slug);
    commit(DELETE_SHORTLIST, slug);
    const { activeShortlist } = state;
    if (activeShortlist && activeShortlist.id && activeShortlist.id === slug) {
      dispatch(GET_SHORTLIST, 0);
    }
    commit(SET_SHORTLIST_COUNT, state.shortlists.length);
  },
  async [FETCH_SHORTLIST_CATALOGS]({ commit }, slug) {
    commit(SET_SHORTLIST_CATALOGS_START);
    const { data } = await ShortlistService.fetchCatalogs(slug);
    commit(SET_SHORTLIST_CATALOGS, data.data);
  },
  async [FETCH_SHORTLIST_PUBLISHER_BOOKS]({ commit }, { slug, publisherSlug }) {
    commit(SET_SHORTLIST_PUBLISHER_BOOKS_START);
    const { data } = await ShortlistService.fetchPublisherBooks(
      slug,
      publisherSlug
    );
    commit(SET_SHORTLIST_PUBLISHER_BOOKS, data.data);
  },
};

const mutations = {
  [SET_SHORTLISTS](state, { shortlists }) {
    state.shortlists = shortlists;
  },
  [SET_ACTIVE_SHORTLIST](state, { shortlist }) {
    state.activeShortlist = shortlist;
  },
  [ADD_SHORTLIST](state, { shortlist }) {
    state.shortlists.push(shortlist);
  },
  [SET_SELECTED_SHORTLIST](state, params) {
    state.selectedShortlist = params;
  },
  [ADD_SHORTLIST_ID](state, { shortlistId }) {
    state.selectedShortlist.shortlistIds.push(shortlistId);
  },
  [DELETE_SHORTLIST_ID](state, { shortlistId }) {
    const index = state.selectedShortlist.shortlistIds
      .map((item) => item)
      .indexOf(shortlistId);
    state.selectedShortlist.shortlistIds.splice(index, 1);
  },
  [UPDATE_SHORTLIST](state, { shortlist }) {
    state.shortlists.forEach((node, index) => {
      if (node.id === shortlist.id) {
        Vue.set(state.shortlists, index, {
          ...node,
          name: shortlist.name,
        });
      }
    });
    if (state.activeShortlist && state.activeShortlist.id === shortlist.id) {
      state.activeShortlist.name = shortlist.name;
    }
  },
  [RESET_SHORTLIST](state) {
    state.shortlists.forEach((node, index) => {
      if (node.id === state.activeShortlist.id) {
        Vue.set(state.shortlists, index, {
          ...node,
          detailCount: 0,
        });
      }
    });
    state.activeShortlist.catalogs = [];
    state.activeShortlist.publishers = [];
  },
  [DELETE_SHORTLIST](state, id) {
    const index = state.shortlists.map((item) => item.id).indexOf(id);
    state.shortlists.splice(index, 1);
  },
  [SET_SHORTLIST_CATALOGS_START](state) {
    state.isShortlistCatalogsLoading = true;
  },
  [SET_SHORTLIST_CATALOGS](state, data) {
    state.shortlistCatalogs = data;
    state.isShortlistCatalogsLoading = false;
  },
  [SET_SHORTLIST_PUBLISHER_BOOKS_START](state) {
    state.isShortlistPublisherBooksLoading = true;
  },
  [SET_SHORTLIST_PUBLISHER_BOOKS](state, data) {
    state.shortlistPublisherBooks = data;
    state.isShortlistPublisherBooksLoading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
