import { SearchService } from "@/common/api.service";
import { FETCH_SEARCH_INDEX, FETCH_RECOMMENDATION } from "./actions.type";
import { SET_SEARCH_INDEX, SET_RECOMMENDATION } from "./mutations.type";

const state = {
  newCatalogs: [],
  popularPublishers: [],
  recentlyViewedCatalogs: [],
  recommendation: {
    books: [],
    catalogs: [],
  },
};

const getters = {
  newCatalogs: (state) => state.newCatalogs,
  popularPublishers: (state) => state.popularPublishers,
  recentlyViewedCatalogs: (state) => state.recentlyViewedCatalogs,
  recommendation: (state) => state.recommendation,
};

const actions = {
  async [FETCH_SEARCH_INDEX]({ commit }) {
    const { data } = await SearchService.getIndex();
    commit(SET_SEARCH_INDEX, data.data);
  },
  async [FETCH_RECOMMENDATION]({ commit }, params) {
    const { data } = await SearchService.getRecommendation(params);
    commit(SET_RECOMMENDATION, data);
  },
};

const mutations = {
  [SET_SEARCH_INDEX](
    state,
    { new_catalogs, popular_publishers, recent_view_catalogs }
  ) {
    state.newCatalogs = new_catalogs;
    state.popularPublishers = popular_publishers;
    state.recentlyViewedCatalogs = recent_view_catalogs;
  },
  [SET_RECOMMENDATION](state, recommendation) {
    state.recommendation = recommendation;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
