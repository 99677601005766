<template>
  <terms-unaccepted-publisher
    v-if="user.type === 'publisher'"
    @accept-terms="handleSubmit"
  />
  <terms-unaccepted-admin v-else @accept-terms="handleSubmit" />
</template>

<script>
import { mapGetters } from "vuex";

import { ACCEPT_TERMS } from "@/store//actions.type";
import TermsUnacceptedAdmin from "./TermsUnacceptedAdmin.vue";
import TermsUnacceptedPublisher from "./TermsUnacceptedPublisher.vue";

export default {
  components: {
    TermsUnacceptedAdmin,
    TermsUnacceptedPublisher,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch(ACCEPT_TERMS);
    },
  },
};
</script>
