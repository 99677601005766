import Vue from "vue";
import VueTailwind from "vue-tailwind";
import VueTailwindSettings from "./plugins/VueTailwind";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import ApiService from "./common/api.service";

import "./scss/index.scss";

import "./plugins/VeeValidate";

Vue.config.productionTip = false;

ApiService.init();

Vue.use(VueTailwind, VueTailwindSettings);
Vue.use(VueSweetalert2);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
