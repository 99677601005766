import Vue from "vue";
import VueRouter from "vue-router";

import JwtService from "@/common/jwt.service";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/books/:handle",
    name: "book",
    component: Home,
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/Terms.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/join-us",
    name: "joinUs",
    component: () => import("../views/JoinUs.vue"),
    meta: { layout: "LayoutAuth" },
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: () => import("../views/ContactUs.vue"),
    meta: { layout: "LayoutAuth" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/SignIn.vue"),
    meta: { layout: "LayoutAuth" },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
    meta: { layout: "LayoutAuth" },
  },
  {
    path: "/reset-password/:token",
    name: "resetPassword",
    component: () => import("../views/ResetPassword.vue"),
    meta: { layout: "LayoutAuth" },
  },
  {
    path: "/message-sent",
    name: "messageSent",
    component: () => import("../views/AuthMessageSent.vue"),
    meta: { layout: "LayoutAuth" },
  },
  {
    path: "/new-password",
    name: "newPassword",
    component: () => import("../views/AuthNewPassword.vue"),
    meta: { layout: "LayoutAuth" },
  },
  {
    path: "/catalogs",
    name: "catalogs",
    component: () => import("../views/Catalogs.vue"),
  },
  {
    path: "/catalogs/:handle",
    name: "catalog",
    component: () => import("../views/CatalogDetail.vue"),
  },
  {
    path: "/publishers",
    name: "publishers",
    component: () => import("../views/Publishers.vue"),
  },
  {
    path: "/publishers/:handle",
    name: "publisher",
    component: () => import("../views/PublisherDetail.vue"),
  },
  {
    path: "/shortlist/:id?",
    name: "shortlist",
    component: () => import("../views/Shortlist.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/shortlist/:id/catalogs",
    name: "shortlistCatalogs",
    component: () => import("../views/ShortlistCatalogs.vue"),
    meta: { requireAuth: true, stickyFooter: true },
  },
  {
    path: "/shortlist/:id/publishers/:publisherHandle",
    name: "shortlistBooks",
    component: () => import("../views/ShortlistBooks.vue"),
    meta: { requireAuth: true, stickyFooter: true },
  },
  {
    path: "*",
    beforeEnter: () => {
      window.location = "/";
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!JwtService.getToken()) {
      next({ name: "login", query: { redirect: to.path } });
    }
  }

  if (to.name === "login" && !!JwtService.getToken()) {
    next("/");
  }

  next();
});

// hide NavigationDuplicated error
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

export default router;
