import Vue from "vue";
import { CatalogService } from "@/common/api.service";
import {
  FETCH_CATALOGS,
  FETCH_MORE_CATALOGS,
  FETCH_CATALOGS_FILTER_PARAMS,
  GET_CATALOG,
  UPDATE_CATALOG_SHORTLISTS,
  FETCH_CATALOG_BOOKS,
  FETCH_MORE_CATALOG_BOOKS,
  FETCH_CATALOG_BOOKS_FILTER_PARAMS,
  UPDATE_CATALOG_BOOK_SHORTLISTS,
} from "./actions.type";
import {
  SET_CATALOGS_START,
  SET_CATALOGS,
  ADD_CATALOGS,
  SET_CATALOGS_FILTER_PARAMS,
  SET_CATALOG,
  SET_CATALOG_SHORTLISTS,
  SET_CATALOG_BOOKS_START,
  SET_CATALOG_BOOKS,
  ADD_CATALOG_BOOKS,
  SET_CATALOG_BOOKS_FILTER_PARAMS,
  SET_CATALOG_BOOK_SHORTLISTS,
} from "./mutations.type";

const state = {
  isCatalogsLoading: false,
  catalogs: [],
  catalogsCount: 0,
  catalogsFilterParams: {
    publishers: [],
  },
  catalog: {},
  catalogPage: {
    breadcrumbsList: [
      {
        href: "/catalogs",
        text: "Сatalogs",
      },
      {
        href: "#",
        text: "",
      },
      {
        href: "#",
        text: "",
      },
    ],
  },
  isCatalogBooksLoading: false,
  catalogBooks: [],
  catalogBooksCount: 0,
  catalogBooksFilterParams: {
    imprints: [],
    subjects: [],
    price: {
      min: 0,
      max: 0,
    },
  },
};

const getters = {
  isCatalogsLoading: (state) => state.isCatalogsLoading,
  catalogs: (state) => state.catalogs,
  catalogsCount: (state) => state.catalogsCount,
  catalogsFilterParams: (state) => state.catalogsFilterParams,
  catalog: (state) => state.catalog,
  catalogPage: (state) => state.catalogPage,
  isCatalogBooksLoading: (state) => state.isCatalogBooksLoading,
  catalogBooks: (state) => state.catalogBooks,
  catalogBooksCount: (state) => state.catalogBooksCount,
  catalogBooksFilterParams: (state) => state.catalogBooksFilterParams,
};

const actions = {
  async [FETCH_CATALOGS]({ commit }, params) {
    commit(SET_CATALOGS_START);
    const { data } = await CatalogService.filter(params);
    commit(SET_CATALOGS, data.data);
  },
  async [FETCH_MORE_CATALOGS]({ commit }, params) {
    const { data } = await CatalogService.filter(params);
    commit(ADD_CATALOGS, data.data);
  },
  async [FETCH_CATALOGS_FILTER_PARAMS]({ commit }, params) {
    const { data } = await CatalogService.fetchFilterParams(params);
    commit(SET_CATALOGS_FILTER_PARAMS, data.data);
  },
  async [GET_CATALOG]({ commit }, slug) {
    const { data } = await CatalogService.get(slug);
    commit(SET_CATALOG, data.data);
  },
  [UPDATE_CATALOG_SHORTLISTS]({ commit }, params) {
    commit(SET_CATALOG_SHORTLISTS, params);
  },
  async [FETCH_CATALOG_BOOKS]({ commit }, { slug, params }) {
    commit(SET_CATALOG_BOOKS_START);
    const { data } = await CatalogService.filterBooks(slug, params);
    commit(SET_CATALOG_BOOKS, data.data);
  },
  async [FETCH_MORE_CATALOG_BOOKS]({ commit }, { slug, params }) {
    const { data } = await CatalogService.filterBooks(slug, params);
    commit(ADD_CATALOG_BOOKS, data.data);
  },
  async [FETCH_CATALOG_BOOKS_FILTER_PARAMS]({ commit }, { slug, params }) {
    const { data } = await CatalogService.fetchBooksFilterParams(slug, params);
    commit(SET_CATALOG_BOOKS_FILTER_PARAMS, data.data);
  },
  [UPDATE_CATALOG_BOOK_SHORTLISTS]({ commit }, params) {
    commit(SET_CATALOG_BOOK_SHORTLISTS, params);
  },
};

const mutations = {
  [SET_CATALOGS_START](state) {
    state.isCatalogsLoading = true;
  },
  [SET_CATALOGS](state, { catalogs, catalogCount }) {
    state.catalogs = catalogs;
    state.catalogsCount = catalogCount;
    state.isCatalogsLoading = false;
  },
  [ADD_CATALOGS](state, { catalogs }) {
    for (var i in catalogs) {
      state.catalogs.push(catalogs[i]);
    }
  },
  [SET_CATALOGS_FILTER_PARAMS](state, params) {
    state.catalogsFilterParams = params;
  },
  [SET_CATALOG](state, { catalog }) {
    state.catalog = catalog;
    Vue.set(
      state.catalogPage.breadcrumbsList[1],
      "text",
      catalog.publisherName
    );
    Vue.set(
      state.catalogPage.breadcrumbsList[1],
      "href",
      `/publishers/${catalog.publisherHandle}`
    );
    Vue.set(state.catalogPage.breadcrumbsList[2], "text", catalog.name);
    Vue.set(
      state.catalogPage.breadcrumbsList[2],
      "href",
      `/catalogs/${catalog.handle}`
    );
  },
  [SET_CATALOG_SHORTLISTS](state, { catalogId, shortlistIds }) {
    state.catalogs.forEach((catalog, index) => {
      if (catalog.id === catalogId) {
        Vue.set(state.catalogs, index, {
          ...catalog,
          shortlistIds: [...shortlistIds],
        });
      }
    });
    if (state.catalog && state.catalog.id === catalogId) {
      state.catalog.shortlistIds = [...shortlistIds];
    }
  },
  [SET_CATALOG_BOOKS_START](state) {
    state.isCatalogBooksLoading = true;
  },
  [SET_CATALOG_BOOKS](state, { books, bookCount }) {
    state.catalogBooks = books;
    state.catalogBooksCount = bookCount;
    state.isCatalogBooksLoading = false;
  },
  [ADD_CATALOG_BOOKS](state, { books }) {
    for (var i in books) {
      state.catalogBooks.push(books[i]);
    }
  },
  [SET_CATALOG_BOOKS_FILTER_PARAMS](state, params) {
    state.catalogBooksFilterParams = params;
  },
  [SET_CATALOG_BOOK_SHORTLISTS](state, { bookId, shortlistIds }) {
    state.catalogBooks.forEach((book, index) => {
      if (book.id === bookId) {
        Vue.set(state.catalogBooks, index, {
          ...book,
          shortlistIds: [...shortlistIds],
        });
      }
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
